import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import {UserService} from '../services/user.service';
import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivateChild {

    lastFailure: string;

    constructor(private userService: UserService,
                private router: Router) {

      if (firebase && firebase.auth && firebase.auth()) {
        firebase.auth().onAuthStateChanged(user => {
          if (user && this.lastFailure) {
            router.navigate([this.lastFailure]);
          }
        });
      }
    }

    canActivateChild(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (!this.userService.isAuthenticated()) {
        this.lastFailure = route.root.children[0].url[0].path;
        this.router.navigate(['login']);
        return false;
      }
      this.lastFailure = null;
      return true;
    }
}


