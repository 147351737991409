import {Injectable} from '@angular/core';
import * as firebase from 'firebase/app';
import {Platform} from '@ionic/angular';
import {Facebook} from "@ionic-native/facebook/ngx";
import {User} from "../model/User";
import {AngularFirestore, AngularFirestoreCollection, DocumentReference} from '@angular/fire/firestore';
import {combineLatest, map, take} from 'rxjs/operators';
import {Router} from "@angular/router";
import {AngularFireAuth} from "@angular/fire/auth";
import {UserService} from "./user.service";
import {forkJoin, Observable} from "rxjs";
import {Chat} from "../model/Chat";
import {Message} from "../model/Message";
import * as moment from "moment";
import QuerySnapshot = firebase.firestore.QuerySnapshot;


@Injectable({
  providedIn: 'root'
})
export class ChatService {

  constructor(
    public platform: Platform,
    public facebook: Facebook,
    private firestore: AngularFirestore,
    public router: Router) {  }

  public getChatList(userId) {
    let chatCollection: AngularFirestoreCollection<Chat> = this.firestore.collection<Chat>('chats',
      ref => ref.where('user', 'array-contains', userId));

    let chats$: Observable<Chat[]> = chatCollection.snapshotChanges().pipe(
      map(chats => {
        return chats.map(a => {
          const data = a.payload.doc.data() as Chat;
          data.id = a.payload.doc.id;
          return data;
        }).sort((a, b) => a.lastMessageTime < b.lastMessageTime ? 1 : -1);
      }),
    );
    return chats$
  }

  getChat(chatId) {
    return this.firestore.collection('chats').doc<Chat>(chatId).valueChanges();
  }

  getMessages(chatId) {
    return this.firestore.collection('chats').doc<Chat>(chatId).collection('messages',
      ref => ref.orderBy('sendAt')).snapshotChanges().pipe(
      map(massages => {
        return massages.map(a => {
          const data = a.payload.doc.data() as Message;
          data.id = a.payload.doc.id;
          return data;
        });
      }),
    );
  }

  getChatsWithNewMessages(userId) {
    return this.firestore.collection<Chat>('chats',
      ref => ref.where('hasNewMessageFor', '==', userId)).snapshotChanges().pipe(
      map(chats => {
        return chats.length
      }),
    );
  }

  addChat(chat: Chat): Promise<Chat> {
    console.log('chat', chat);
    return new Promise<Chat>((resolve, reject) => {
      this.firestore.collection<Chat>('chats',
        ref => ref
          .where("user", "array-contains", chat.user[0])
          .where("listingKey", "==", chat.listingKey)
      ).get().toPromise()
        .then((result: QuerySnapshot) => {
        console.log('result', result);
        if(result.empty) {
          this.firestore.collection('chats').add(chat).then((doc: DocumentReference) => {
            console.log('doc', doc);
            doc.get().then((docSnap) => {
              console.log('docSnap', docSnap);
              console.log('docSnap.data()', docSnap.data());
              resolve({...docSnap.data(), id: docSnap.id} as Chat);
            });
          });
        } else {
          console.log('result.docs', result.docs);
          console.log('result.docs[0]', result.docs[0]);
          console.log('result.docs[0].data()', result.docs[0].data());
          resolve({...result.docs[0].data(), id: result.docs[0].id} as Chat)
        }
      }).catch(reject);
    });
  }

  addMessage(message: Message, chatId) {
    return this.firestore.collection('chats').doc<Chat>(chatId).collection('messages').add(message);
  }

  updateMessage(chatId: string, messageId: string, data: Partial<Message>) {
    return this.firestore.collection('chats').doc<Chat>(chatId)
      .collection('messages').doc<Message>(messageId).update(data);

  }

  updateChat(chatId: string, data: Partial<Chat>) {
    return this.firestore.collection('chats').doc<Chat>(chatId).update(data);

  }

  getDiffDate(time) {
    if (!time)
      return '-';
    let tmp: number;
    if((tmp = moment().diff(moment.unix(time.seconds), "minutes")) < 60)
      return tmp+ ' min. ago';
    else if ((tmp = moment().diff(moment.unix(time.seconds), "hours")) < 24)
      return tmp+ ' hour(s) ago';
    else if ((tmp = moment().diff(moment.unix(time.seconds), "days")) < 7)
      return tmp+ ' day(s) ago';
    else if ((tmp = moment().diff(moment.unix(time.seconds), "weeks")) < 4)
      return tmp+ ' week(s) ago';
    else
      return moment().diff(moment.unix(time.seconds), "months")+ ' mouth(s) ago';
  }
}
