import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {AngularFireAuth, AngularFireAuthModule} from '@angular/fire/auth';
import {ChatService} from './services/chat.service';
import {UserService} from './services/user.service';
import {UiService} from './services/ui.service';
import {AuthGuard} from './guards/auth.guard';
import * as firebase from 'firebase';
import {environment} from '../environments/environment';
import {AngularFireModule} from '@angular/fire';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {Facebook} from "@ionic-native/facebook/ngx";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {MatFormFieldModule, MatInputModule} from "@angular/material";
import { ImagePicker } from '@ionic-native/image-picker/ngx';
import {Keyboard} from '@ionic-native/keyboard/ngx';
import {GoogleMapsService} from './services/maps.service';
import {PayPal} from "@ionic-native/paypal/ngx";
import {UIUtils} from "./util/uiUtils";
import {GeneralUtils} from "./util/generalUtils";
import {HttpClientModule} from "@angular/common/http";

firebase.initializeApp(environment.firebase);

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    MatFormFieldModule,
    MatInputModule,
    HttpClientModule,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    AngularFireAuth,
    ChatService,
    UserService,
    UiService,
    AuthGuard,
    Facebook,
    Keyboard,
    GoogleMapsService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    ImagePicker,
    PayPal,
    UIUtils,
    GeneralUtils
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
