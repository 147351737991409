import {AlertController, LoadingController, ToastController} from "@ionic/angular";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class UiService {

  loading: any;

  constructor(
    private toastController: ToastController,
    private alertController: AlertController,
    private loadingController: LoadingController
  ) {}

  showToast(message: string, duration: number = 5000, position: 'bottom' | 'middle' | 'top' = 'bottom'): Promise<HTMLIonToastElement> {
    return new Promise(resolve => {
      this.toastController.create({
        message: message,
        duration: duration,
        position:position,
        showCloseButton: true
      }).then(toast => {
        toast.present();
        resolve(toast);
      });
    });
  }


  showAlert(message: string, title?: string, buttons?: any, inputs?: any): Promise<any> {
    return new Promise(resolve => {
      this.alertController.create({
        header: title,
        message: message,
        buttons: buttons
      }).then(alert => {
        alert.present();
        resolve(alert)
      });
    });
  }

  showLoading(message?: string) {
    return new Promise(resolve => {
      this.loadingController.create({
        message: message
      }).then(loading => {
        this.loading = loading;
        loading.present().then(() => {
          resolve(loading);
        });
      });
    });
  }

  hideLoading() {
    if(this.loading) {
      this.loadingController.dismiss().then(()=> this.loading = null);
    }
  }
}
