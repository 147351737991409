import {Injectable} from '@angular/core';
import * as firebase from 'firebase/app';
import {firestore} from 'firebase/app';
import {Platform} from '@ionic/angular';
import {Facebook} from "@ionic-native/facebook/ngx";
import {User} from "../model/User";
import {AngularFirestore} from '@angular/fire/firestore';
import {take} from 'rxjs/operators';
import {Router} from "@angular/router";
import {AngularFireAuth} from "@angular/fire/auth";
import {HttpClient} from "@angular/common/http";
import {ChatService} from "./chat.service";
import {Observable} from "rxjs";
import {LocalStorageService} from "./local-storage.service";
import {EmailService} from './email.service';
import Timestamp = firestore.Timestamp;


@Injectable({
  providedIn: 'root'
})
export class UserService {

  public user: User;
  public currentUser: User = new User();
  public $currentUser: Observable<User> = new Observable<User>();
  private emailService: EmailService;

  constructor(
    public chatService: ChatService,
    public platform: Platform,
    public facebook: Facebook,
    private firestore: AngularFirestore,
    public http: HttpClient,
    public afAuth: AngularFireAuth,
    public router: Router,
    private localStorageService: LocalStorageService) {
  }

  getCurrentUser() {
    return this.localStorageService.getUser() || this.currentUser
  }

  setCurrentUser(user){
    console.log('UID: ', user.uid);
    this.user = new User();
    Object.keys(this.user).forEach(key => this.user[key] = user[key]);
    this.localStorageService.setUser(user as User);
    console.log('u', user);
    this.$currentUser = this.firestore.doc<User>(`users/${user.uid}`).valueChanges();
    this.$currentUser.subscribe( user => {
      console.log('user', user)
      this.currentUser = user
    });
  }

  updateEmail(newEmail: string, user: User) {
    return new Promise<any>((resolve, reject) => {
      firebase.auth().currentUser.updateEmail(newEmail).then(res => {
          resolve();
        },
        err => reject(err));
    });

  }

  updatePassword(newPassword: string, oldPassword: string, email: string) {

    let user = this.afAuth.auth.currentUser;
    let credentials = firebase.auth.EmailAuthProvider.credential(
      email.toString(),
      oldPassword.toString()
    )
    return new Promise<any>((resolve, reject) => {
      user.reauthenticateAndRetrieveDataWithCredential(credentials).then(
        () => {
          user.updatePassword(newPassword).then(() => {
            resolve()
          }).catch(error => {
            reject(error);
          });
        }
      ).catch(error => {
        resolve(error);
      });
    });
  }

  registerUser(value) {
    return new Promise<any>((resolve, reject) => {
      firebase.auth().createUserWithEmailAndPassword(value.email, value.password)
        .then(res => {

          this.user = new User();
          Object.keys(this.user).forEach(key => this.user[key] = res.user[key]);

          this.user.address = value.user.address;
          this.user.dateOfBirth = value.user.dateOfBirth;
          this.user.description = value.user.description;
          this.user.firstName = value.user.firstName;
          this.user.lastName = value.user.lastName;
          this.user.fbUrl = value.user.fbUrl;
          this.user.phoneNumber = value.user.phoneNumber;
          this.user.paymentDetails = 'details go here';
          this.user.chatIds = [];
          this.user.registerDate = Timestamp.now();
          if (value.user.idVerification || value.user.idVerification != undefined) {
            this.user.idVerification = value.user.idVerification;
          } else {
            this.user.idVerification = '';
          }
          this.user.photoURL = 'https://finanzmesse.ch/userdata/uploads/referenten/avatar.jpg';
          //Start with empty to avoid error with undefined values
          this.user.sortCode = value.user.sortCode ? value.user.sortCode : ""
          this.user.accountNumber = value.user.accountNumber ? value.user.accountNumber : ""
          this.user.billingAddress = value.user.billingAddress ? value.user.billingAddress : ""
          //sardine only
          for (let key in value.user.bankAccountForm) {
            this.user[key] = value.user.bankAccountForm[key];
          }
          this.save(this.user).then(resolve);
        }).catch(err => {
          console.log('Error: ', err);
          reject(err)
      });
    });
  }

  loginUser(value) {
    return new Promise<any>((resolve, reject) => {
      firebase.auth().signInWithEmailAndPassword(value.email, value.password)
        .then(
          res => {
            console.log('Custom Auth Response', res);
            this.setCurrentUser(res.user);
            resolve(this.user.uid);
          },
          err => {
            this.user = null;
            reject(err);
          });
    });
  }

  loginFacebook() {
    return new Promise((resolve, reject) => {
      this.facebook.login(['email'])
        .then(response => {
          const facebookCredential = firebase.auth.FacebookAuthProvider
            .credential(response.authResponse.accessToken);
          console.log("Facebook success: ", response);
          firebase.auth().signInWithCredential(facebookCredential)
            .then(res => {
              console.log('Facebook Auth Response', res);
              this.setCurrentUser(res.user);
              this.getUser(res.user.uid).then(user => {
                console.log('getUser Response', user);
                if (user) {
                  this.setCurrentUser(user);
                  resolve(res.user.uid);
                } else {
                  let newUser = {
                    registerDate: Timestamp.fromDate(new Date()),
                    firstName: (res.additionalUserInfo.profile as any).first_name,
                    lastName: (res.additionalUserInfo.profile as any).last_name,
                    email: (res.additionalUserInfo.profile as any).email || res.user.email,
                    fbUrl: 'https://www.facebook.com/profile.php?uid=' + res.user.uid,
                    photoURL: (res.additionalUserInfo.profile as any).picture
                      && (res.additionalUserInfo.profile as any).picture.data &&
                      (res.additionalUserInfo.profile as any).picture.data.url,
                    isAnonymous: false,
                    uid: res.user.uid
                  } as User;
                  this.save(newUser).then(user => {
                    this.setCurrentUser(newUser);
                    this.sendNewUserEmail(newUser);
                    resolve(newUser.uid);
                  });
                }
              });
            });
        }).catch((error) => {
        console.log(error);
        this.user = null;
        reject(error.message || error);
      });
    });
  }

  logoutUser() {
    return new Promise((resolve, reject) => {
      if (firebase.auth().currentUser) {
        firebase.auth().signOut()
          .then(() => {
            this.user = null;
            resolve();
          }).catch((error) => {
          reject();
        });
      }
    });
  }

  isAuthenticated() {
    return this.user != null;
  }

  getUser(uid) {
    return new Promise((resolve, reject) => {
      this.firestore.collection('users').doc(uid).get().pipe(take(1)).subscribe(response => {
        resolve(response.data() as User);
      });
    });
  }

  save(user) {
    return new Promise((resolve, reject) => {
      if (user.uid) {
        return this.update(user).then(resolve).catch(reject);
      } else {
        this.firestore.collection('users').add({...user}).then(result => {
          user.uid = result.id;
          return this.update(user).then(resolve).catch(reject);
        });
      }
    });
  }

  private update(user): Promise<any> {
    return new Promise((resolve, reject) => {
      console.log('User: ', user);
      this.firestore.collection('users').doc(user.uid).set({...user}, {merge: true}).then(result => {
        resolve(result);
      }).catch((err) => {
        console.log('err:', err);
        reject(err);
      });
    });
  }

  sendEmail(chatId, amount){
    this.chatService.getChat(chatId).pipe(take(1)).subscribe( chat =>{
      this.getUser(chat.user[1]).then( (renter: User) =>{
        this.sendUserPaymentEmail(renter.firstName+" "+renter.lastName, amount);
        this.sendRenterPaymentEmail(renter.firstName+" "+renter.lastName, renter.email, amount);
      })
    })
  }

  sendUserPaymentEmail(renterName, amount) {
    let recipient = this.currentUser.email;
    let subject = "Sardines - Payment Confirmation";
    let content = "<div>Hi "+ this.currentUser.firstName + ",</div><br>" +
      "<div>A payment for your booking has been sent! You have just sent a payment in the amount of $"+amount+" to "+renterName+".</div><br>" +
      "<div>If this is a partial payment in the form of a deposit, you can send additional payments in the app until the trip has been paid in full.</div><br>" +
      "<div>If the payment in full has been sent, great! There’s nothing else that needs to be done except to enjoy your trip.</div><br>" +
      "<div>We’re so glad we could help you <strong>Find Your Pack</strong> Please send any questions you have to ‘sardineshelp@gmail.com’.</div><br>" +
      "<div>Sincerely,</div><br>" +
      "<div>Your Friends at Sardines</div><br>";

    this.emailService.sendEmail(recipient, subject, content);
    this.emailService.sendEmail('sardineshelp@gmail.com', subject, content);
  }

  sendRenterPaymentEmail(renterName, email, amount) {
    let recipient = email;
    let subject = "Sardines - Payment Confirmation";
    let content = "<div>Hi "+renterName+",</div><br>" +
      "<div>A payment for your booking has been received! You have just received payment in the amount of $"+amount+" from "+ this.currentUser.firstName+" "+this.currentUser.lastName+ ".</div><br>" +
      "<div>If this is a partial payment in the form of a deposit, you can request more payments in the app until the trip has been paid in full.</div><br>" +
      "<div>If the payment in full has been received, great! There’s nothing else that needs to be done except to enjoy your trip.</div><br>" +
      "<div>We’re so glad we could help you <strong>Find Your Pack</strong>. Please send any questions you have to ‘sardineshelp@gmail.com’.</div><br>" +
      "<div>Sincerely,</div><br>" +
      "<div>Your Friends at Sardines</div><br>";

    this.emailService.sendEmail(recipient, subject, content);
    this.emailService.sendEmail('sardineshelp@gmail.com', subject, content);
  }

  sendNewUserEmail(user) {
    let to = user.email;
    let subject = "Thanks for Signing Up with Sardines!";
    let content = `<div style="font-family: Arial">
        <div>Hi ${user.firstName},</div>
        <br/>
        <p>Thanks for joining the Sardines community!</p>
        <p>Now that you're a Sardine (or Seeker), don't forget to follow us on Instagram,
            Twitter and Facebook #SardinesLLC  You can also tune into our travel podcast, “O's Odyssey” on Spotify, G
            oogle Podcasts, Apple Podcasts and other places.  We also want to provide answers to some FAQ's about who we are:
        </p>
        <ol>
            <li>
                <h4>Am I a Sardine or a Seeker?</h4>
                <p>Any points of (primary) contact who have booked accommodations to share with others are deemed Sardines.
                    The Seekers are those looking to share accommodations and will pay the Sardine on our website.
                    Seekers can use the search fields to filter dates and locations of where you're looking to travel to.</p>
            </li>
            <li>
                <h4>If I'm a Sardine Seeker, should I pay the Sardine through Venmo, Paypal or Cash App?</h4>
                <p>We strongly discourage this practice since we have noway of ensuring the Sardine you're booking with is
                    providing accurate information. Once you exchange funds outside of the platform, we can not be of
                    assistance should a dispute arise.</p>
            </li>
            <li>
                <h4>I'm a Sardine and my Sardine Seeker(s) have sent my money.  When will I be paid?</h4>
                <p>Currently, we process payments every Saturday afterthe trip has been completed.  We do have plans to do
                    this more frequently as the company grows. Sardines should receive their due funds within a few business
                    days after.</p>
            </li>
            <li>
                <h4>Why the name Sardines?</h4>
                <p>In addition to resembling a pack of Sardines once you've “found your pack” (accommodations to share with
                    your fellow Sardines), we like the idea of people hiding and being sought much like the childhood game
                    of Sardines.  Google it if you haven't already. It's hide and seek with a twist!</p>
            </li>
            <li>
                <h4>I have a problem.  How do I get help?</h4>
                <p>We want to be of assistance wherever we can. Please email <a href="mailto:sardineshelp@gmail.com">sardineshelp@gmail.com</a> with any questions you may have.</p>
            </li>
        </ol>
        <p>Now that we've gotten some basics out of the way, enjoy your trip and feel free to tag us in your pics!</p>
        <p>Sincerely, <br/>Odofoley Oquaye <br/>Founder of Sardines</p>
    </div>`;
    this.emailService.sendEmail(to, subject, content);
  }


}
