import {Injectable} from "@angular/core";
import {AlertController, LoadingController, ToastController} from "@ionic/angular";

@Injectable()
export class UIUtils {

    //loading: any;

    constructor(
        //private toastController: ToastController,
        private alertController: AlertController,
        //private loadingController: LoadingController
    ) {}


    async showAlert(message: string, title?: string, subTitle?: string, buttons?: any) {
        const alert = await this.alertController.create({
            header: title,
            subHeader: subTitle,
            message: message,
            buttons: buttons
        });
        await alert.present();
    }

    /*showToast(message: string, duration: number = 2000, position: 'bottom' | 'middle' | 'top' = 'bottom'): Promise<any> {
        return new Promise(resolve => {
            this.toastController.create({
                message: message,
                duration: duration,
                position:position,
                showCloseButton: true
            }).present().then(resolve);
        });
    }*/

    /*showAlert(message: string, title?: string, buttons?: any, inputs?: any): Promise<any> {
        return new Promise(resolve => {
            this.alertController.create({
                title: title,
                message: message,
                buttons: buttons
            }).present().then(resolve);
        });
    }*/

    /*showLoading(message?: string) {
        return new Promise(resolve => {
            this.loading = this.loadingController.create({
                content: message
            });
            this.loading.present().then(resolve);
        });
    }

    hideLoading() {
        if(this.loading)
            this.loading.dismissAll();
    }*/
}
