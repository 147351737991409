import {Injectable} from '@angular/core';
import {User} from '../model/User';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  private LOCAL_DATA_NAME = "sardines_data";

  private localData = localStorage.getItem(this.LOCAL_DATA_NAME)
    ? JSON.parse(localStorage.getItem(this.LOCAL_DATA_NAME))
    : {};

  Observer: StoreDataObserver = new StoreDataObserver();

  getProperty(property) {
    return this.localData[property];
  }

  setProperty(property, value) {
    this.localData[property] = value;
    localStorage.setItem(this.LOCAL_DATA_NAME, JSON.stringify(this.localData));
    this.Observer.notify(property, value);
  }

  removeProperty(property) {
    this.localData[property] = null;
    delete this.localData[property];
    localStorage.setItem(this.LOCAL_DATA_NAME, JSON.stringify(this.localData));
  }

  getUser(): User {
    return this.getProperty("user");
  }


  setUser(value: User) {
    this.setProperty("user", value);
  }

  removeUser() {
    if (this.getProperty("user")) {
      this.removeProperty("user");
    }
  }

  observe(property, handler) {
    this.Observer.observe(property,handler)
  }
}

class StoreDataObserver {

  listeners: Array<StorageDataListener>;

  constructor() {
    this.listeners = [];
  }

  observe(property, handler) {
    this.listeners.push({
      targetProperty: property,
      handler: handler
    });
  }

  notify(property, value) {
    this.listeners.forEach((listener) => {
      if (listener.targetProperty === property) {
        listener.handler(value);
      }
    });
  }
}

class StorageDataListener {
  targetProperty: string;
  handler: Function;
}
