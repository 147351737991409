import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {AuthGuard} from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: './home/home.module#HomePageModule',
    canActivateChild: [AuthGuard]
  },
  {
    path: 'terms',
    loadChildren: './terms/terms.module#TermsPageModule',
  },
  {path: 'profile', loadChildren: './register/register.module#RegisterPageModule', canActivateChild: [AuthGuard]},
  { path: 'login', loadChildren: './login/login.module#LoginPageModule' },
  { path: 'signup', loadChildren: './signup/signup.module#SignUpPageModule' },
  { path: 'register', loadChildren: './register/register.module#RegisterPageModule' },
  { path: 'continue-sign-up', loadChildren: './continue-sign-up/continue-sign-up.module#ContinueSignUpPageModule' },
  { path: 'welcome', loadChildren: './welcome/welcome.module#WelcomePageModule' },
  { path: 'add-card', loadChildren: './add-card/add-card.module#AddCardPageModule', canActivateChild: [AuthGuard] },
  { path: 'deposit-info', loadChildren: './deposit-info/deposit-info.module#DepositInfoPageModule', canActivateChild: [AuthGuard] },
  { path: 'trip-description', loadChildren: './trip-description/trip-description.module#TripDescriptionPageModule', canActivateChild: [AuthGuard] },
  { path: 'share-space-info', loadChildren: './share-space-info/share-space-info.module#ShareSpaceInfoPageModule', canActivateChild: [AuthGuard] },
  { path: 'share-space-info/:listingKey', loadChildren: './share-space-info/share-space-info.module#ShareSpaceInfoPageModule', canActivateChild: [AuthGuard] },
  { path: 'chat-list', loadChildren: './chat-tab/chat-list/chat-list.module#ChatListPageModule', canActivateChild: [AuthGuard] },
  { path: 'chat/:chat', loadChildren: './chat-tab/chat/chat.module#ChatPageModule', canActivateChild: [AuthGuard] },
  { path: 'paypal', loadChildren: './paypal/paypal.module#PaypalPageModule', canActivateChild: [AuthGuard] },
  { path: 'listing-details', loadChildren: './listing-details/listing-details.module#ListingDetailsPageModule', canActivateChild: [AuthGuard] },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
