import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import {ChildActivationEnd, Router, UrlTree} from "@angular/router";
import {filter} from "rxjs/operators";
import {ChatService} from "./services/chat.service";
import {Observable} from "rxjs";
import {UserService} from "./services/user.service";
import {LocalStorageService} from "./services/local-storage.service";
import * as firebase from "firebase";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {

  currentPage: string;
  numberOfUnreadChats: number;

  constructor(
    private chatService: ChatService,
    private localStorageService: LocalStorageService,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    private userService: UserService) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();

      this.router.events.pipe(filter(e => e instanceof ChildActivationEnd)).subscribe((e:any) => {
          this.currentPage = this.getActiveRouteSegmentPath();
      });

      this.localStorageService.observe('uid', (uid) => {
        this.chatService.getChatsWithNewMessages(uid).subscribe( number => this.numberOfUnreadChats = number)
      });

      if (firebase && firebase.auth && firebase.auth()) {
        firebase.auth().onAuthStateChanged(user => {
          if (user && user.uid) {
            this.chatService.getChatsWithNewMessages(user.uid).subscribe( number => this.numberOfUnreadChats = number)
            this.userService.getUser(user.uid).then((theUser: any) => {
              if(theUser && theUser.uid)
                this.userService.setCurrentUser(theUser);
                this.router.navigate(['home']);
            });
          } else {
            this.userService.user = null;
          }
        });
      }
    });
  }

  enableTabs(){
    if (this.currentPage == 'login' || this.currentPage == 'register' || this.currentPage == 'signup' || this.currentPage == 'payment-details/register') {
      return 'app-component-is-login';
    }
    else{
      return '';
    }
  }

  public getActiveRouteSegmentPath() {
    let path = '';
    const tree: UrlTree = this.router.parseUrl(this.router.url);
    if(tree.root.children.primary && tree.root.children.primary.children) {
      path = tree.root.children.primary.segments[0].path;
    }
    else if(tree.root.segments && tree.root.segments.length) {
      path = tree.root.segments[0].path;
    }
    return path;
  }
}
