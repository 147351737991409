import {firestore} from 'firebase/app';
import Timestamp = firestore.Timestamp;

export class User {
  uid: string;
  isAnonymous: boolean;
  firstName: string;
  lastName: string;
  email: string;
  address: string;
  billingAddress?: string;
  sortCode?: string;
  accountNumber?: string;
  phoneNumber: string ;
  dateOfBirth: string;
  paymentDetails?: any;
  idVerification?: any;
  description: string;
  photoURL: string;
  chatIds: any[];
  fbUrl: string;
  registerDate: Timestamp;

  constructor(){
    this.uid = '';
    this.isAnonymous = true;
    this.firstName = '';
    this.lastName = '';
    this.email = '';
    this.address = '';
    this.billingAddress = '';
    this.sortCode = '';
    this.accountNumber = '';
    this.phoneNumber = '';
    this.dateOfBirth = '';
    this.idVerification = '';
    this.description = '';
    this.photoURL = 'https://randomuser.me/api/portraits/lego/6.jpg';
    this.chatIds = [];
    this.fbUrl = '';
    this.registerDate = null;
  }
}
