import {Injectable} from "@angular/core";
import * as moment from "moment";
import {FormGroup} from "@angular/forms";
import {GoogleMapsService} from '../services/maps.service';
import {reject} from "q";

@Injectable()
export class GeneralUtils {
    searchData: {search_query: string, search_places_predictions: Array<google.maps.places.AutocompletePrediction>} = {
        search_query: '',
        search_places_predictions: []
    };

    public fee: number = 0.025;

    constructor(public googleMapsService: GoogleMapsService){}

    formatDate(date: number) {
        const formatedDate = moment(new Date((date * 1000)).toDateString())
            .format('LL');

        return formatedDate;
    }


    rentPeriod(startDate: number, endDate: number) {
        let fromDate = this.formatDate(startDate);
        let toDate = this.formatDate(endDate);

        return moment(toDate).diff(moment(fromDate), 'days');
    }

    searchPlacesPredictions(form: FormGroup, field: string){
        return new Promise((resolve, reject) => {
            let query: any;
            if(form.controls.location){
                query = form.controls.location.get(field).value
            } else {
                query = form.get(field).value;
            }

            if (query && query.length >= 1) {
                this.googleMapsService.getPlacePredictions(query).subscribe(
                    places_predictions => {
                        resolve(places_predictions);
                    },
                    e => {
                        console.log("onError: %s", e);
                        reject(e);
                    },
                    () => {
                        //console.log("onCompleted");
                    }
                )
            } else {
                resolve([]);
            }
        });
    }

    addWeekDayToDate(date: string) {
        return moment(date).format('ddd') + ' ' + date;
    }


    totalAmount(originalPrice: string): string {
        return ((+originalPrice * this.fee) + +originalPrice).toFixed(2).toString();
    }
}
